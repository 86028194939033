import "core-js/modules/es.array.sort.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-tabs", {
    staticClass: "log-com",
    attrs: {
      type: "border-card",
      "before-leave": _vm.handleBeforeChange
    },
    on: {
      "tab-click": _vm.handleTabChange
    },
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_vm._l(_vm.tabs, function (item) {
    return _c("el-tab-pane", {
      key: item.id,
      attrs: {
        name: item.id,
        label: item.label
      }
    }, [_vm.activeName === item.id && _vm.log.length ? _vm._l(_vm.log, function (el) {
      return _c("min-com", {
        key: el.id,
        attrs: {
          item: el
        }
      });
    }) : _c("el-empty", {
      attrs: {
        description: "暂无数据"
      }
    })], 2);
  }), _c("el-tab-pane", {
    attrs: {
      name: "5"
    }
  }, [_c("el-button", {
    attrs: {
      slot: "label",
      type: "text"
    },
    slot: "label"
  }, [_vm._v(_vm._s(_vm.sort === 1 ? "时间升序" : "时间降序"))])], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "phone-call-com"
  }, [_c("div", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "top"
  }, [_c("div", {
    staticClass: "num"
  }, [_vm._v(_vm._s(_vm.phone))]), _c("div", {
    staticClass: "time"
  }, [_vm._v(_vm._s(_vm.showTime))])]), _c("div", {
    staticClass: "tip"
  }, [_c("el-result", {
    attrs: {
      icon: "warning",
      title: "通话中请勿刷新网页，刷新动作按主动挂断处理！"
    }
  })], 1)]), _c("div", {
    staticClass: "right"
  }, [_c("el-button", {
    attrs: {
      icon: "el-icon-phone",
      circle: true
    },
    on: {
      click: _vm.handupCall
    }
  }, [_vm._v("挂断")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
export var letterToNumberObj = {
  1: 'A',
  2: 'B',
  3: 'C',
  4: 'D',
  5: 'E',
  6: 'F',
  7: 'G',
  8: 'H',
  9: 'I',
  10: 'J',
  11: 'K',
  12: 'L',
  13: 'M',
  14: 'N',
  15: 'O'
};

/**
 * 根据评价内容，展示不同类型的标签
 * 0有效 success 1静默 info 2未接通 danger 3未评价 warning
 */
export var evaluationTagObj = {
  0: {
    type: 'success',
    name: '有效'
  },
  1: {
    type: 'info',
    name: '建议静默'
  },
  2: {
    type: 'danger',
    name: '联系未果'
  },
  3: {
    type: 'warning',
    name: '未评价'
  }
};

/**
 * 最新评价
 */
export var evaluationList = [{
  id: 2,
  label: '联系未果'
}, {
  id: 0,
  label: '有效'
}, {
  id: 1,
  label: '建议静默'
}, {
  id: 3,
  label: '未评价'
}];

// 分配方式
export var assignType = [{
  id: 0,
  label: '自动'
}, {
  id: 1,
  label: '手动'
}];

// 分配情况
export var distributionStatus = [{
  id: 0,
  label: '未分配'
}, {
  id: 1,
  label: '已分配'
}];

// 有无归属
export var isOwner = [{
  id: false,
  label: '无'
}, {
  id: true,
  label: '有'
}];
export var accountOpt = [{
  id: 0,
  label: '账户ID'
}, {
  id: 1,
  label: '账户名称'
}];

// 分配方式
export var createCardType = [{
  id: 1,
  label: '手动'
}, {
  id: 2,
  label: '自动'
}];
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ai-call-com"
  }, [_c("i", {
    staticClass: "el-icon-chat-line-round",
    on: {
      click: _vm.handleDialog
    }
  }), _c("el-dialog", {
    attrs: {
      "append-to-body": "",
      title: "通话详情",
      "close-on-click-modal": false,
      "close-on-press-escape": false,
      "before-close": _vm.handleDialog,
      visible: _vm.dialog.visible,
      width: "520px",
      "custom-class": "ai-call-dialog"
    },
    on: {
      "update:visible": function updateVisible($event) {
        return _vm.$set(_vm.dialog, "visible", $event);
      }
    }
  }, [_c("div", {
    staticClass: "ai-call-dialog-main"
  }, _vm._l(_vm.attrs["msg-list"], function (item) {
    return _c("div", {
      key: item.msg,
      class: _vm.getClass(item.senderType)
    }, [_c("div", {
      staticClass: "role"
    }, [_vm._v(_vm._s(_vm.getSender(item.senderType)))]), _c("div", {
      staticClass: "content"
    }, [_vm._v(_vm._s(item.msg))])]);
  }), 0)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "app"
    }
  }, [_c("keep-alive", [!_vm.$route.meta.noCache ? _c("router-view") : _vm._e()], 1), _vm.$route.meta.noCache ? _c("router-view") : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
import "core-js/modules/es.array.concat.js";
import moment from 'moment';
import store from '@/store';
import { handupCall } from '@/layout/components/phoneCall';
export default {
  name: 'PhoneCallCom',
  data: function data() {
    return {
      time: moment(),
      showTime: '',
      timer: null,
      phone: store.state.user.call.phone,
      handupCall: handupCall
    };
  },
  mounted: function mounted() {
    this.init();
  },
  destroyed: function destroyed() {
    this.clearTimer();
  },
  methods: {
    init: function init() {
      var _this = this;
      this.timer = setInterval(function () {
        var _moment$duration$_dat = moment.duration(moment().diff(_this.time))._data,
          hours = _moment$duration$_dat.hours,
          minutes = _moment$duration$_dat.minutes,
          seconds = _moment$duration$_dat.seconds;
        _this.showTime = "".concat(hours > 9 ? hours : "0".concat(hours), " : ").concat(minutes > 9 ? minutes : "0".concat(minutes), " : ").concat(seconds > 9 ? seconds : "0".concat(seconds));
      }, 1000);
    },
    clearTimer: function clearTimer() {
      clearInterval(this.timer);
    }
  }
};
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "alarm-clock",
    attrs: {
      title: "回访闹钟",
      visible: _vm.dialogVisible,
      "close-on-click-modal": false,
      width: "330px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("el-descriptions", {
    attrs: {
      title: "该联系啦！",
      column: 1,
      size: "medium"
    }
  }, [_c("el-descriptions-item", {
    attrs: {
      label: "姓名"
    }
  }, [_vm._v(_vm._s(_vm.name))]), _vm.contactPhone && _vm.contactPhone.length ? _c("el-descriptions-item", {
    attrs: {
      label: "手机"
    }
  }, _vm._l(_vm.contactPhone, function (item) {
    return _c("span", {
      key: item
    }, [_c("span", [_vm._v(_vm._s(_vm.encrypt(0, item)))]), _c("i", {
      staticClass: "el-icon-copy-document",
      on: {
        click: function click($event) {
          return _vm.copy(item, $event);
        }
      }
    })]);
  }), 0) : _vm._e(), _vm.contactWechat && _vm.contactWechat.length ? _c("el-descriptions-item", {
    attrs: {
      label: "微信"
    }
  }, _vm._l(_vm.contactWechat, function (item) {
    return _c("span", {
      key: item
    }, [_c("span", [_vm._v(_vm._s(_vm.encrypt(1, item)))]), _c("i", {
      staticClass: "el-icon-copy-document",
      on: {
        click: function click($event) {
          return _vm.copy(item, $event);
        }
      }
    })]);
  }), 0) : _vm._e()], 1), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        return _vm.alarmAgain(5);
      }
    }
  }, [_vm._v("5分钟后提醒")]), _c("el-button", {
    on: {
      click: function click($event) {
        return _vm.alarmAgain(10);
      }
    }
  }, [_vm._v("10分钟后提醒")]), _c("el-button", {
    on: {
      click: _vm.close
    }
  }, [_vm._v("确认")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
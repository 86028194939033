var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      "close-on-click-modal": false,
      visible: _vm.visible,
      "before-close": _vm.closePopup,
      "show-close": false,
      "append-to-body": "",
      width: "100%",
      "custom-class": _vm.getLocation()
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.visible = $event;
      }
    }
  }, [_c("div", {
    class: ["popup-img", "popup-img".concat(_vm.type), "content"],
    style: {
      backgroundImage: "url(".concat(_vm.url, ")")
    }
  }, [_c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.content)
    }
  })]), _c("el-button", {
    staticClass: "close-button",
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.closePopup
    }
  }, [_c("i", {
    staticClass: "el-icon-circle-close"
  })])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "welcome-back",
    attrs: {
      center: "",
      visible: _vm.dialogVisible,
      "show-close": false,
      "close-on-click-modal": false,
      width: "560px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "header",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("img", {
    attrs: {
      src: _vm.welcomeBackIcon,
      alt: "welcome_back_icon"
    }
  }), _c("h2", [_vm._v("欢迎回来")])]), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "msg"
  }, [_vm._v(_vm._s(_vm.msg))]), _c("div", {
    staticClass: "tip"
  }, [_vm._v("离开状态：超过一定时间未操作，不再获得数据分配。10点-20点，限40分钟；其余时间 限20分钟。")])]), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    staticClass: "btn",
    on: {
      click: _vm.close
    }
  }, [_vm._v("我知道了")])], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
import "core-js/modules/es.array.concat.js";
/*
 * @Author: zsy
 * @Date: 2021-07-27 19:05:37
 * @LastEditors: zsy
 * @LastEditTime: 2021-07-27 19:05:37
 */
import request from '@/utils/request';
import qs from 'qs';
var BASE_URL = 'api/customers';

// 我的客户名片列表
export function myCustomersList(params) {
  return request({
    url: "".concat(BASE_URL, "/personal?").concat(qs.stringify(params, {
      indices: false
    })),
    method: 'get'
  });
}

// 编辑评价
export function edit(data) {
  return request({
    url: "".concat(BASE_URL, "/").concat(data.id, "/evaluations"),
    method: 'post',
    data: data
  });
}

/**
 * @description 查询单个名片
 * @param  {String} info 客户ID或联系方式
 */
export function customersInfo(info) {
  return request({
    url: "".concat(BASE_URL, "/info/").concat(info),
    method: 'get'
  });
}

/**
 * @description 查询单个名片简要信息
 * @param  {Number} id
 */
export function briefInfo(param) {
  return request({
    url: "".concat(BASE_URL, "/").concat(param, "/brief-info"),
    method: 'get'
  });
}

/**
 * @description 新增意向
 * @param  {Object} data
 */
export function addIntentions(data) {
  return request({
    url: "".concat(BASE_URL, "/intentions"),
    method: 'post',
    data: data
  });
}

/**
 * @description 查询评价
 * @param  {Number} id 客户ID
 */
export function customersEvaluations(id) {
  return request({
    url: "".concat(BASE_URL, "/").concat(id, "/evaluations"),
    method: 'get'
  });
}

/**
 * @description 查询闹钟
 * @param {Object} params
 * @param  {Number} cId 客户id
 * @param {Number} id 当前登录用户id
 */
export function getClock(params) {
  return request({
    url: "".concat(BASE_URL, "/").concat(params.cId, "/clock"),
    method: 'GET',
    params: params
  });
}

/**
 * @description 设置回访闹钟
 * @param {Number} id 客户id
 * @param  {String} status 状态，0-关闭，1-打开
 * @param  {String} revisitTime 回收时间
 */
export function addClock(id, data) {
  return request({
    url: "".concat(BASE_URL, "/").concat(id, "/clock"),
    method: 'put',
    data: data
  });
}

/**
 * @description 修改闹钟启用状态
 * @param {Number} id 闹钟id
 * @param  {String} status 状态，0-关闭，1-打开
 */
export function editClockStatus(id, data) {
  return request({
    url: "api/revisitReminder/".concat(id, "/status?status=").concat(data.status),
    method: 'put'
  });
}

/**
 * @description 修改回访闹钟
 * @param {Number} id 闹钟id
 * @param  {String} revisitTime
 */
export function editClock(data) {
  return request({
    url: "api/revisitReminder/postpone",
    method: 'put',
    data: data
  });
}

/**
 * @description 放弃
 * @param  {Object} data
 * @param  {Number} id
 * @param {String} remark 跟进备注
 */
export function abandonAPI(data) {
  return request({
    url: "".concat(BASE_URL, "/").concat(data.id, "/freed"),
    method: 'DELETE',
    data: data
  });
}

/**
 * @description 查询我的客户首咨、当期回访、跨期回访、公海领取的累计数
 */
export function myCustomersCount() {
  return request({
    url: "".concat(BASE_URL, "/personal/count"),
    method: 'get'
  });
}

/**
 * @description 批量废弃
 */

export function batchDrop(data) {
  return request({
    url: "".concat(BASE_URL, "/freed-batch"),
    method: 'delete',
    data: data
  });
}

/**
 * @description 获取各评价等级的名片数v6.0
 * @param  {object} params
 */
export function getIntentionCount(params) {
  return request({
    url: "".concat(BASE_URL, "/intention-level/count"),
    method: 'GET',
    params: params
  });
}
export default {
  edit: edit,
  customersInfo: customersInfo,
  batchDrop: batchDrop,
  customersEvaluations: customersEvaluations,
  addIntentions: addIntentions,
  briefInfo: briefInfo,
  editClockStatus: editClockStatus,
  editClock: editClock,
  myCustomersCount: myCustomersCount
};
import Vue from 'vue';
import Router from 'vue-router';
import Layout from '../layout/index';
Vue.use(Router);

/**
 * @description: 公共路由
 * @param {*}
 * @return {*}
 */
export var constantRouterMap = [{
  path: '/login',
  meta: {
    title: '登录',
    noCache: true
  },
  component: function component(resolve) {
    return require(['@/views/login/index.vue'], resolve);
  },
  hidden: true
}, {
  path: '/404',
  component: function component(resolve) {
    return require(['@/views/features/404'], resolve);
  },
  hidden: true
}, {
  path: '/401',
  component: function component(resolve) {
    return require(['@/views/features/401'], resolve);
  },
  hidden: true
}, {
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component(resolve) {
      return require(['@/views/features/redirect'], resolve);
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component(resolve) {
      return require(['@/views/home'], resolve);
    },
    name: 'Dashboard',
    meta: {
      title: '首页',
      icon: 'index',
      affix: true,
      noCache: true
    }
  }]
}, {
  path: '/user',
  component: Layout,
  hidden: true,
  redirect: 'noredirect',
  children: [{
    path: 'center',
    component: function component(resolve) {
      return require(['@/views/system/user/center'], resolve);
    },
    name: '个人中心',
    meta: {
      title: '个人中心'
    }
  }]
}];

/**
 * @description: 异步挂载的路由,动态需要根据权限加载的路由表
 * @param {*}
 * @return {*}
 */
export var asyncRouterMap = [{
  alwaysShow: true,
  children: [{
    component: 'system/user/index',
    hidden: false,
    meta: {
      title: '用户管理',
      icon: 'peoples',
      noCache: false
    },
    name: 'User',
    path: 'user'
  }, {
    component: 'system/role/index',
    hidden: false,
    meta: {
      title: '角色管理',
      icon: 'role',
      noCache: false
    },
    name: 'Role',
    path: 'role'
  }, {
    component: 'system/menu/index',
    hidden: false,
    meta: {
      title: '菜单管理',
      icon: 'menu',
      noCache: false
    },
    name: 'Menu',
    path: 'menu'
  }, {
    component: 'system/dept/index',
    hidden: false,
    meta: {
      title: '部门管理',
      icon: 'dept',
      noCache: false
    },
    name: 'Dept',
    path: 'dept'
  }, {
    component: 'system/job/index',
    hidden: false,
    meta: {
      title: '岗位管理',
      icon: 'Steve-Jobs',
      noCache: false
    },
    name: 'Job',
    path: 'job'
  }, {
    component: 'system/dict/index',
    hidden: false,
    meta: {
      title: '字典管理',
      icon: 'dictionary',
      noCache: false
    },
    name: 'Dict',
    path: 'dict'
  }, {
    component: 'system/timing/index',
    hidden: false,
    meta: {
      title: '任务调度',
      icon: 'timing',
      noCache: false
    },
    name: 'Timing',
    path: 'timing'
  }],
  component: 'Layout',
  hidden: false,
  meta: {
    icon: 'system',
    noCache: true,
    title: '系统配置'
  },
  name: '系统配置',
  path: '/system',
  redirect: 'noredirect'
}, {
  alwaysShow: true,
  children: [{
    component: 'monitor/online/index',
    hidden: false,
    meta: {
      title: '在线用户',
      icon: 'Steve-Jobs',
      noCache: false
    },
    name: 'OnlineUser',
    path: 'online'
  }, {
    component: 'monitor/log/index',
    hidden: false,
    meta: {
      title: '操作日志',
      icon: 'log',
      noCache: false
    },
    name: 'Log',
    path: 'logs'
  }, {
    component: 'monitor/log/errorLog',
    hidden: false,
    meta: {
      title: '异常日志',
      icon: 'error',
      noCache: false
    },
    name: 'ErrorLog',
    path: 'errorLog'
  }, {
    component: 'monitor/server/index',
    hidden: false,
    meta: {
      title: '服务监控',
      icon: 'monitor',
      noCache: false
    },
    name: 'ServerMonitor',
    path: 'server'
  }, {
    component: 'monitor/sql/index',
    hidden: true,
    meta: {
      title: 'SQL监控',
      icon: 'sqlMonitor',
      noCache: false
    },
    name: 'Sql',
    path: 'druid'
  }],
  component: 'Layout',
  hidden: false,
  meta: {
    icon: 'monitor',
    noCache: true,
    title: '系统监控'
  },
  name: '系统监控',
  path: '/monitor',
  redirect: 'noredirect'
}, {
  alwaysShow: true,
  children: [{
    component: 'mnt/server/index',
    hidden: false,
    meta: {
      title: '服务器',
      icon: 'server',
      noCache: false
    },
    name: 'ServerDeploy',
    path: 'mnt/serverDeploy'
  }, {
    component: 'mnt/app/index',
    hidden: false,
    meta: {
      title: '应用管理',
      icon: 'app',
      noCache: false
    },
    name: 'App',
    path: 'mnt/app'
  }, {
    component: 'mnt/deploy/index',
    hidden: false,
    meta: {
      title: '部署管理',
      icon: 'deploy',
      noCache: false
    },
    name: 'Deploy',
    path: 'mnt/deploy'
  }, {
    component: 'mnt/deployHistory/index',
    hidden: false,
    meta: {
      title: '部署备份',
      icon: 'backup',
      noCache: false
    },
    name: 'DeployHistory',
    path: 'mnt/deployHistory'
  }, {
    component: 'mnt/database/index',
    hidden: false,
    meta: {
      title: '数据库管理',
      icon: 'database',
      noCache: false
    },
    name: 'Database',
    path: 'mnt/database'
  }],
  component: 'Layout',
  hidden: false,
  meta: {
    icon: 'mnt',
    noCache: true,
    title: '运维管理'
  },
  name: 'Mnt',
  path: '/Mnt',
  redirect: 'noredirect'
}, {
  alwaysShow: true,
  children: [{
    component: 'teachDesk/teachDesk/index',
    hidden: false,
    meta: {
      title: '教务工作台',
      icon: 'education',
      noCache: false
    },
    name: 'TeachDesk',
    path: 'teachDesk'
  }],
  component: 'Layout',
  hidden: false,
  meta: {
    icon: 'education',
    noCache: true,
    title: '教务'
  },
  name: 'Teach',
  path: '/teachDesk',
  redirect: 'noredirect'
}, {
  alwaysShow: true,
  children: [{
    component: 'studentCenter/studentManage/index',
    hidden: false,
    meta: {
      title: '学生管理',
      icon: 'people',
      noCache: false
    },
    name: 'StudentManage',
    path: 'studentManage'
  }, {
    component: 'studentCenter/studyPlanManage/index',
    hidden: false,
    meta: {
      title: '报读管理',
      icon: 'codeConsole',
      noCache: false
    },
    name: 'StudyPlanManage',
    path: 'studyPlanManage'
  }, {
    component: 'studentCenter/studentMessage/index',
    hidden: true,
    meta: {
      title: '学生信息',
      icon: 'peoples',
      noCache: false
    },
    name: 'StudentMessage',
    path: 'studentMessage'
  }],
  component: 'Layout',
  hidden: false,
  meta: {
    icon: 'user',
    noCache: true,
    title: '学生中心'
  },
  name: 'StudentCenter',
  path: '/studentCenter',
  redirect: 'noredirect'
}, {
  alwaysShow: true,
  children: [{
    component: 'teachCenter/classManage/index',
    hidden: false,
    meta: {
      title: '班级管理',
      icon: 'peoples',
      noCache: false
    },
    name: 'ClassManage',
    path: 'classManage'
  }, {
    component: 'teachCenter/editClass/index',
    hidden: true,
    meta: {
      title: '编辑/添加班级',
      icon: 'peoples',
      noCache: false
    },
    name: 'EditClass',
    path: 'editClass'
  }, {
    component: 'teachCenter/teacherManage/index',
    hidden: false,
    meta: {
      title: '教师管理',
      icon: 'role',
      noCache: false
    },
    name: 'TeacherManage',
    path: 'teacherManage'
  }, {
    component: 'teachCenter/scheduleManage/index',
    hidden: false,
    meta: {
      title: '课程表',
      icon: 'menu',
      noCache: false
    },
    name: 'ScheduleManage',
    path: 'scheduleManage'
  }, {
    component: 'teachCenter/dataStat/index',
    hidden: true,
    meta: {
      title: '数据统计',
      icon: 'peoples',
      noCache: false
    },
    name: 'DataStat',
    path: 'dataStat'
  }, {
    component: 'teachCenter/majorPlan/index',
    hidden: false,
    meta: {
      title: '专业学习计划',
      icon: 'peoples',
      noCache: false
    },
    name: 'MajorPlan',
    path: 'majorPlan'
  }, {
    component: 'teachCenter/editMajorPlan/index',
    hidden: true,
    meta: {
      title: '添加/编辑专业学习计划',
      icon: 'peoples',
      noCache: false
    },
    name: 'EditMajorPlan',
    path: 'editMajorPlan'
  }, {
    component: 'teachCenter/termPlanManage/index',
    hidden: false,
    meta: {
      title: '开课计划管理',
      icon: 'peoples',
      noCache: false
    },
    name: 'TermPlanManage',
    path: 'termPlanManage'
  }, {
    component: 'teachCenter/editTermPlan/index',
    hidden: true,
    meta: {
      title: '添加/编辑开课计划',
      icon: 'peoples',
      noCache: false
    },
    name: 'EditTermPlan',
    path: 'editTermPlan'
  }],
  component: 'Layout',
  hidden: false,
  meta: {
    icon: 'user1',
    noCache: true,
    title: '教学中心'
  },
  name: '教学中心',
  path: '/teachCenter',
  redirect: 'noredirect'
}, {
  alwaysShow: true,
  children: [{
    component: 'baseMessage/schoolManage/index',
    hidden: false,
    meta: {
      title: '学校管理',
      icon: 'server',
      noCache: false
    },
    name: 'SchoolManage',
    path: 'schoolManage'
  }, {
    component: 'baseMessage/editSchool/index',
    hidden: true,
    meta: {
      title: '编辑学校',
      icon: 'peoples',
      noCache: false
    },
    name: 'EditSchool',
    path: 'editSchool'
  }, {
    component: 'baseMessage/courseManage/index',
    hidden: false,
    meta: {
      title: '课程管理',
      icon: 'sys-tools',
      noCache: false
    },
    name: 'CourseManage',
    path: 'courseManage'
  }, {
    component: 'baseMessage/majorManage/index',
    hidden: false,
    meta: {
      title: '专业管理',
      icon: 'swagger',
      noCache: false
    },
    name: 'MajorManage',
    path: 'majorManage'
  }],
  component: 'Layout',
  hidden: false,
  meta: {
    icon: 'icon',
    noCache: true,
    title: '基础信息管理'
  },
  name: '基础信息管理',
  path: '/baseMessage',
  redirect: 'noredirect'
}];
export default new Router({
  // mode: 'hash',
  mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});
import request from '@/utils/request';

/**
 * @description 获取省市区
 * @param {object} params
 */
export function getArea(params) {
  return request({
    url: 'api/base/areas',
    method: 'GET',
    params: params
  });
}
export var uploadFileUrl = "".concat(process.env.VUE_APP_BASE_API, "api/fastdfsStorage/pictures");

/**
 * @description: 上传图片
 * @param {*}
 * @return {*}
 */

export var uploadImageUrl = "".concat(process.env.VUE_APP_BASE_API, "api/fastdfsStorage/pictures");

/**
 * @description: 上传文件
 * @param {*}
 * @return {*}
 */

export var fastUploadFileUrl = "".concat(process.env.VUE_APP_BASE_API, "api/fastdfsStorage/files");

/**
 * @description 获取dataEase数据面板地址
 * @param  {object} params
 * @param  {0 | 1 | 2 | 3 | 4 | 5 | 6} type 0:周期个人-目标-月数据
 * 1:周期个人-目标-周数据、2:周期个人-指标-月数据、3:周期个人-指标-周数据
 * 4:周期团队-目标-月数据、5:周期团队-目标-周数据、6:周期团队-指标-月数据、7:周期团队-指标-周数据、8:招生职期分析
 */
export function getDataEase(params) {
  return request({
    url: 'api/dataEaseSite/team-cycle',
    method: 'GET',
    params: params
  });
}
var BASE_URL = 'api/product-score-rule';

/**
 * @description 查询教务系统的项目
 * @param  {object} params
 * @param  {string} name?
 */
export function getProject(params) {
  return request({
    url: "".concat(BASE_URL, "/listProjects"),
    method: 'GET',
    params: params
  });
}

/**
 * @description 查询教务系统的项目类型v6.0
 * @param  {object} params
 * @param  {string} name?
 */
export function getProjectType(params) {
  return request({
    url: "".concat(BASE_URL, "/listProjectTypes"),
    method: 'GET',
    params: params
  });
}

/**
 * @description 查询教务系统的报读学校v6.0
 * @param  {object} params
 * @param  {string} name?
 */
export function getSchools(params) {
  return request({
    url: "".concat(BASE_URL, "/listSchools"),
    method: 'GET',
    params: params
  });
}

/**
 * @param  {object} params
 * @param  {number} schoolId 学校id
 */
export function getMajorBySchool(params) {
  return request({
    url: "".concat(BASE_URL, "/listMajors"),
    method: 'GET',
    params: params
  });
}
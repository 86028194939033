import _objectSpread from "/var/jenkins_home/workspace/crm-admin-uat/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
/*
 * @Author: xuzf
 * @Date: 2021-01-07 14:48:06
 * @LastEditors: xuzf
 * @LastEditTime: 2021-02-24 17:13:42
 * @Description: file content
 */
import router from './routers';
import store from '@/store';
import Config from '@/settings';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import { getToken } from '@/utils/auth'; // getToken from cookie
import { buildMenus } from '@/api/system/menu';
import { filterAsyncRouter } from '@/store/modules/permission';
NProgress.configure({
  showSpinner: false
}); // NProgress Configuration

var whiteList = ['/login']; // no redirect whitelist

router.beforeEach(function (to, from, next) {
  if (to.meta.title) {
    document.title = Config.title;
  }
  NProgress.start();
  if (getToken()) {
    // 已登录且要跳转的页面是登录页
    if (to.path === '/login') {
      next({
        path: '/'
      });
      NProgress.done();
    } else {
      reuse(next, to);
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      if (!getToken() && to.query.token) {
        store.dispatch('redirectLogin', to.query.token);
        reuse(next, to);
      } else {
        next("/login?redirect=".concat(to.fullPath)); // 否则全部重定向到登录页
        NProgress.done();
      }
    }
  }
});
var reuse = function reuse(next, to) {
  if (store.getters.roles.length === 0) {
    // 判断当前用户是否已拉取完user_info信息
    store.dispatch('GetInfo').then(function () {
      // 拉取user_info
      // 动态路由，拉取菜单
      loadMenus(next, to);
    }).catch(function (err) {
      console.log(err);
      store.dispatch('LogOut').then(function () {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    });
    // 登录时未拉取 菜单，在此处拉取
  } else if (store.getters.loadMenus) {
    // 修改成false，防止死循环
    store.dispatch('updateLoadMenus');
    loadMenus(next, to);
  } else {
    next();
  }
};
export var loadMenus = function loadMenus(next, to) {
  // const sdata = JSON.parse(JSON.stringify(asyncRouterMap))
  // const rdata = JSON.parse(JSON.stringify(asyncRouterMap))
  // const sidebarRoutes = filterAsyncRouter(sdata)
  // const rewriteRoutes = filterAsyncRouter(rdata, true)
  // rewriteRoutes.push({ path: '*', redirect: '/404', hidden: true })

  // store.dispatch('GenerateRoutes', rewriteRoutes).then(() => { // 存储路由
  //   router.addRoutes(rewriteRoutes) // 动态添加可访问路由表
  //   next({ ...to, replace: true })
  // })
  // store.dispatch('SetSidebarRouters', sidebarRoutes)

  buildMenus().then(function (res) {
    var sdata = JSON.parse(JSON.stringify(res));
    var rdata = JSON.parse(JSON.stringify(res));
    var sidebarRoutes = filterAsyncRouter(sdata);
    var rewriteRoutes = filterAsyncRouter(rdata, true);
    rewriteRoutes.push({
      path: '*',
      redirect: '/404',
      hidden: true
    });
    store.dispatch('GenerateRoutes', rewriteRoutes).then(function () {
      // 存储路由
      router.addRoutes(rewriteRoutes); // 动态添加可访问路由表
      next(_objectSpread(_objectSpread({}, to), {}, {
        replace: true
      }));
    });
    store.dispatch('SetSidebarRouters', sidebarRoutes);
  });
};
router.afterEach(function () {
  NProgress.done(); // finish progress bar
});
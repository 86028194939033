var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("vue-plyr", [_c("audio", {
    attrs: {
      crossorigin: "",
      playsinline: ""
    }
  }, [_c("source", {
    attrs: {
      src: _vm.src,
      type: "audio/mp3"
    }
  })])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
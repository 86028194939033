import Dict from './Dict';
var install = function install(Vue) {
  Vue.mixin({
    data: function data() {
      if (this.$options.dicts instanceof Array) {
        var dict = {
          dict: {},
          label: {}
        };
        return {
          dict: dict
        };
      }
      return {};
    },
    created: function created() {
      var _this = this;
      if (this.$options.dicts instanceof Array) {
        new Dict(this.dict).init(this.$options.dicts, function () {
          _this.$nextTick(function () {
            _this.$emit('dictReady');
          });
        });
      }
    }
  });
};
export default {
  install: install
};
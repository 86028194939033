import "core-js/modules/es.array.concat.js";
import request from '@/utils/request';
var BASE_URL = 'api/invalidTypes';

/**
 * @description 新增静默类型
 * @param  {object} data
 * @param {string} name
 * @param {string} detail
 * @param {0 | 1} status 状态，0-停用、1-启用
 */
function add(data) {
  return request({
    url: "".concat(BASE_URL),
    method: 'POST',
    data: data
  });
}

/**
 * @description 修改静默类型
 * @param  {object} data
 * @param {number} id
 * @param {string} name
 * @param {string} detail
 * @param {0 | 1} status 状态，0-停用、1-启用
 */
function edit(data) {
  return request({
    url: "".concat(BASE_URL),
    method: 'PUT',
    data: data
  });
}

/**
 * @description 修改静默类型生效状态
 * @param  {object} data
 * @param {number} id
 * @param {0 | 1} effect 是否生效，0-不生效、1-生效
 */
function editEffect(data) {
  return request({
    url: "".concat(BASE_URL, "/").concat(data.id, "/effect?effect=").concat(data.effect),
    method: 'PUT'
  });
}

/**
 * @description 获取静默类型选项
 * @param {object} params
 * @param {1 | 2} type 1-静默、2-联系未果
 */
export function getInvalidTypeOpts(params) {
  return request({
    url: "".concat(BASE_URL),
    method: 'GET',
    params: params
  });
}
export default {
  add: add,
  edit: edit,
  editEffect: editEffect
};
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
export default {
  name: 'VuePlyr',
  props: {
    /** Options object for plyr config. **/
    options: {
      type: Object,
      required: false,
      default: function _default() {
        return {
          'controls': ['play', 'progress', 'current-time', 'mute', 'volume', 'settings', 'download'],
          'speed': {
            selected: 1,
            options: [1, 1.5, 2]
          }
        };
      }
    }
  },
  data: function data() {
    return {
      player: {}
    };
  },
  computed: {
    opts: function opts() {
      var options = this.options;
      if (!Object.prototype.hasOwnProperty.call(this.options, 'hideYouTubeDOMError')) {
        options.hideYouTubeDOMError = true;
      }
      return options;
    }
  },
  mounted: function mounted() {
    this.player = new Plyr(this.$el, this.opts);
  },
  beforeUnmount: function beforeUnmount() {
    try {
      this.player.destroy();
    } catch (e) {
      if (!(this.opts.hideYouTubeDOMError && e.message === 'The YouTube player is not attached to the DOM.')) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  },
  render: function render() {
    var slots = this.$slots.default;
    return typeof slots === 'function' ? slots()[0] : slots;
  }
};
import { validatePhone, validateQQ, validateWxchat } from '@/utils/validate';
export var contactType = [{
  id: 1,
  label: '手机号'
}, {
  id: 2,
  label: '微信号'
}, {
  id: 3,
  label: 'QQ号'
}];
export var validateNum = function validateNum(type, val) {
  var tip = '';
  switch (type) {
    case 1:
      validatePhone([], val, function (data) {
        tip = data;
      });
      break;
    case 2:
      validateWxchat([], val, function (data) {
        tip = data;
      });
      break;
    case 3:
      validateQQ([], val, function (data) {
        tip = data;
      });
      break;
    default:
      break;
  }
  return tip;
};
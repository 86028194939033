import Logo from '@/assets/svgs/login/logo_icon.svg';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '优隼CRM',
      logo: Logo
    };
  },
  created: function created() {
    switch (process.env.VUE_APP_ENV_NAME) {
      case 'dev':
        this.title += '(开发)';
        break;
      case 'test':
        this.title += '(测试)';
        break;
      case 'uat':
        this.title += '(uat)';
        break;
      default:
        break;
    }
  }
};
import "core-js/modules/es.array.concat.js";
import request from '@/utils/request';
import qs from 'qs';
var BASE_URL = 'api/customers';

/**
 * @description 查询意向客户列表
 */
export function intentionsList(params) {
  return request({
    url: "".concat(BASE_URL, "/intentions?").concat(qs.stringify(params, {
      indices: false
    })),
    method: 'get'
  });
}

/**
 * @description 客户详情组件，查询收藏列表中的总收藏数和已收藏数
 */
export function getMyCollected() {
  return request({
    url: "".concat(BASE_URL, "/stars"),
    method: 'GET'
  });
}
/**
 * @description 收藏或取消收藏
 * @param {Number} 客户id
 * @param  {boolean} isStar 收藏或取消
 */
export function editStars(data) {
  return request({
    url: "".concat(BASE_URL, "/").concat(data.id, "/stars?isStar=").concat(data.isStar),
    method: 'put',
    data: data
  });
}

/**
 * @description 领取收藏
 * @param  {Number} id
 */
export function getStar(id) {
  return request({
    url: "".concat(BASE_URL, "/stars/").concat(id),
    method: 'put'
  });
}
export default {
  editStars: editStars,
  getStar: getStar
};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dropdown", {
    staticClass: "online-status-com",
    attrs: {
      trigger: "click"
    },
    on: {
      command: _vm.handleStatus
    }
  }, [_c("el-tag", {
    attrs: {
      type: _vm.status[_vm.actived].tag
    }
  }, [_vm._v(" " + _vm._s(_vm.status[_vm.actived].label) + " ")]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown",
      szie: "medium"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    staticClass: "online-status-com-item",
    attrs: {
      command: 1 - _vm.actived
    }
  }, [_c("el-tag", {
    attrs: {
      type: _vm.status[1 - _vm.actived].tag
    }
  }, [_vm._v(" " + _vm._s(_vm.status[1 - _vm.actived].label) + " ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
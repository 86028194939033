var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "rightPanel",
    staticClass: "rightPanel-container",
    class: {
      show: _vm.show
    }
  }, [_c("div", {
    staticClass: "rightPanel-background"
  }), _c("div", {
    staticClass: "rightPanel"
  }, [_c("div", {
    staticClass: "rightPanel-items"
  }, [_vm._t("default")], 2)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
/*
 * @Author: xuzf
 * @Date: 2021-01-20 11:40:57
 * @LastEditors: xuzf
 * @LastEditTime: 2021-02-05 16:59:11
 */
import axios from 'axios';
import router from '@/router/routers';
import { Notification } from 'element-ui';
import store from '../store';
import { getToken, removeToken } from '@/utils/auth';
import Config from '@/settings';
import Cookies from 'js-cookie';
import WelcomeBack from '@/components/welcomeBack';

// 创建axios实例
var service = axios.create({
  baseURL: window.location.href.indexOf('david.picp.vip') === -1 ? process.env.VUE_APP_BASE_API : 'https://david.picp.vip/',
  // api 的 base_url
  // baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/', // api 的 base_url
  timeout: Config.timeout // 请求超时时间
});

// request拦截器
service.interceptors.request.use(function (config) {
  if (getToken()) {
    config.headers['Authorization'] = getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  config.headers['Content-Type'] = 'application/json';
  return config;
}, function (error) {
  Promise.reject(error);
});

// response 拦截器
service.interceptors.response.use(function (response) {
  return response.data;
}, function (error) {
  // 兼容blob下载出错json提示
  if (error.response.data instanceof Blob && error.response.data.type.toLowerCase().indexOf('json') !== -1) {
    var reader = new FileReader();
    reader.readAsText(error.response.data, 'utf-8');
    reader.onload = function (e) {
      var errorMsg = JSON.parse(reader.result).message;
      Notification.error({
        title: errorMsg,
        duration: 5000
      });
    };
  } else {
    var code = 0;
    try {
      code = error.response.status || error.response.data.status || error.response.data.code;
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        Notification.error({
          title: '网络请求超时',
          duration: 5000
        });
        return Promise.reject(error);
      }
    }
    if (code) {
      switch (code) {
        case 401:
          removeToken();
          store.dispatch('LogOut').then(function () {
            // 用户登录界面提示
            Cookies.set('point', 401);
            location.reload();
          });
          break;
        case 403:
          router.push({
            path: '/401'
          });
          break;
        case 418:
          WelcomeBack.install({
            msg: error.response.data.message
          });
          break;
        default:
          // eslint-disable-next-line no-case-declarations
          var errorMsg = error.response.data.message;
          if (errorMsg !== undefined) {
            Notification.error({
              title: errorMsg,
              duration: 5000
            });
          }
          break;
      }
    } else {
      Notification.error({
        title: '接口请求失败',
        duration: 5000
      });
    }
  }
  return Promise.reject(error);
});
export default service;
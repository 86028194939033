import _objectSpread from "/var/jenkins_home/workspace/crm-admin-uat/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import VuePlyr from './index.vue';
VuePlyr.install = function (app) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (options.plyr) {
    VuePlyr.props.options.default = function () {
      return _objectSpread({}, options.plyr);
    };
  }
  app.component(VuePlyr.name, VuePlyr);
};
export default VuePlyr;
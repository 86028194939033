import Vue from 'vue';
import Clock from './index.vue';
var AlarmClock = Vue.extend(Clock);
Clock.install = function (data) {
  var instance = new AlarmClock({
    data: data
  }).$mount();
  document.body.appendChild(instance.$el);

  // Vue.nextTick(() => {
  //   instance.open();
  // });
};
export default Clock;
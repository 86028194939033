var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "customer-input"
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.disabled
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, [_c("template", {
    slot: "suffix"
  }, [_c("i", {
    class: ["el-icon-edit-outline", {
      can: _vm.canEdit
    }],
    on: {
      click: _vm.handleEdit
    }
  }), _c("img", {
    class: ["delete-icon", {
      can: _vm.canDelete
    }],
    attrs: {
      src: _vm.canDelete ? require("@/assets/svgs/customerInput/delete_actived_icon.svg") : require("@/assets/svgs/customerInput/delete_icon.svg"),
      alt: "delete_icon"
    },
    on: {
      click: _vm.handleDelete
    }
  }), _c("i", {
    staticClass: "el-icon-document-copy",
    on: {
      click: _vm.handleCopy
    }
  })])], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
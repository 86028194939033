import VuePlyr from './vuePlyr/index';
export default {
  name: 'CallPlayerCom',
  components: {
    VuePlyr: VuePlyr
  },
  props: {
    src: {
      required: true,
      type: String
    }
  }
};
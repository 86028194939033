import request from '@/utils/request';
export function login(username, password, code, uuid) {
  return request({
    url: 'auth/login',
    method: 'post',
    data: {
      username: username,
      password: password,
      code: code,
      uuid: uuid
    }
  });
}
export function getInfo() {
  return request({
    url: 'authInfo',
    method: 'get'
  });
}
export function getCodeImg() {
  return request({
    url: 'auth/code',
    method: 'get'
  });
}
export function logout() {
  return request({
    url: 'auth/logout',
    method: 'delete'
  });
}
export function loginByWxWork(data) {
  return request({
    url: "auth/wxLogin?code=".concat(data, "&type=4"),
    method: 'get'
  });
}

// https://david.picp.vip/auth/wxLogin
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar"
  }, [_c("div", {
    staticClass: "breadcrumb-container"
  }, [_c("hamburger", {
    attrs: {
      id: "hamburger-container",
      "is-active": _vm.sidebar.opened
    },
    on: {
      toggleClick: _vm.toggleSideBar
    }
  }), _c("breadcrumb", {
    attrs: {
      id: "breadcrumb-container"
    }
  })], 1), _c("div", {
    staticClass: "right-menu"
  }, [_c("el-form", [_c("el-form-item", {
    attrs: {
      error: _vm.drawer.error
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请输入联系方式查询",
      loading: _vm.drawer.loading,
      "remote-method": _vm.handleSearch,
      filterable: "",
      remote: ""
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.drawer.cardId,
      callback: function callback($$v) {
        _vm.$set(_vm.drawer, "cardId", $$v);
      },
      expression: "drawer.cardId"
    }
  }, _vm._l(_vm.drawer.opts, function (item) {
    return _c("el-option", {
      key: item.customerId,
      attrs: {
        label: "".concat(item.customerId, "\uFF08").concat(item.ownerName, "\uFF09"),
        value: item.customerId
      }
    });
  }), 1)], 1)], 1), _c("el-tag", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      type: _vm.notices ? "danger" : "info",
      plain: ""
    },
    on: {
      click: _vm.openUnreadMsg
    }
  }, [_vm._v(_vm._s(_vm.notices) + "条消息未读")]), _c("online-status-com", {
    attrs: {
      "online-status": _vm.onlineStatus
    }
  }), _c("el-tag", [_vm._v(_vm._s(_vm.user.nickName))]), _c("el-dropdown", {
    staticClass: "avatar-container right-menu-item hover-effect",
    attrs: {
      trigger: "click"
    }
  }, [_c("div", {
    staticClass: "avatar-wrapper"
  }, [_c("img", {
    staticClass: "user-avatar",
    attrs: {
      src: _vm.user.avatarName ? _vm.baseApi + "avatar/" + _vm.user.avatarName : _vm.Avatar
    }
  }), _c("i", {
    staticClass: "el-icon-caret-bottom"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("span", {
    staticStyle: {
      display: "block"
    },
    on: {
      click: function click($event) {
        _vm.show = true;
      }
    }
  }, [_c("el-dropdown-item", [_vm._v(" 布局设置 ")])], 1), _c("router-link", {
    attrs: {
      to: "/user/center"
    }
  }, [_c("el-dropdown-item", [_vm._v(" 个人中心 ")])], 1), _c("span", {
    staticStyle: {
      display: "block"
    },
    on: {
      click: _vm.open
    }
  }, [_c("el-dropdown-item", {
    attrs: {
      divided: ""
    }
  }, [_vm._v(" 退出登录 ")])], 1)], 1)], 1)], 1), _c("el-dialog", {
    attrs: {
      "append-to-body": "",
      "before-close": _vm.closeUnreadMsg,
      visible: _vm.noticeDialog,
      title: "通知中心",
      width: "600px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.noticeDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "notice"
  }, [_c("div", {
    staticClass: "notice_center"
  }, _vm._l(_vm.noticeList, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "notice_center_content"
    }, [_c("p", [_vm._v(_vm._s(item.createTime))]), _c("p", [_vm._v(_vm._s(item.message))])]);
  }), 0), _c("el-pagination", {
    attrs: {
      layout: "total, prev, pager, next, sizes",
      "page-size": _vm.query.size,
      "current-page": _vm.queryPage.page,
      total: _vm.paperListTotal
    },
    on: {
      "current-change": _vm.paperListPageChange,
      "size-change": _vm.paperListSizeChange
    }
  })], 1)]), _c("customer-drawer", {
    attrs: {
      visible: _vm.drawer.isExist && _vm.drawer.visible,
      "c-id": _vm.drawer.cardId,
      "drawer-modal": false
    },
    on: {
      closeDrawer: _vm.handleDrawer
    }
  }), _c("GoodNewsPopup", {
    attrs: {
      visible: _vm.goodNewsVisible,
      content: _vm.goodNews.content ? _vm.goodNews.content : "",
      type: _vm.goodNews.image,
      location: _vm.goodNews.location,
      url: _vm.dict.label.good_news_pic[_vm.goodNews.image]
    },
    on: {
      closePopup: _vm.closePopup
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
import "core-js/modules/es.number.constructor.js";
export default {
  name: 'GoodNewsPopup',
  /**
  *预览值
    主角昵称：优小课
    主角所属部门：招生处（一）
    订单应缴金额：18,000.00
    订单实缴金额：6,000.00
    主角本月业绩：12,345.00
    主角本月目标：16,000.00
    主角本周业绩：6666.00
    主角本周目标：20000.00
    主角所属部门本月业绩：234,000.00
    主角所属部门本月目标：880,000.00
    主角所属部门本周业绩：150,000.00
    主角所属部门本周目标：270,000.00
  **/
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      required: true
    },
    type: {
      type: Number,
      default: null
    },
    location: {
      type: Number,
      default: null
    },
    url: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      locationClass: ['center', 'right-top', 'right-bottom']
    };
  },
  methods: {
    closePopup: function closePopup() {
      this.$emit('closePopup');
    },
    getLocation: function getLocation() {
      var locationClass = this.locationClass,
        location = this.location;
      return "good-news-popup ".concat(locationClass[location - 1]);
    }
  }
};
import "core-js/modules/es.array.concat.js";
import Cookies from 'js-cookie';
import Config from '@/settings';
var TokenKey = Config.TokenKey;
var ENV = process.env.VUE_APP_ENV_NAME;
var ENV_NAME = process.env.VUE_APP_ENV_NAME.toUpperCase();
var EnvTokenKey = "".concat(ENV_NAME, "-").concat(TokenKey);
var ENV_DOMAIN = {
  'prod': '.uoocuniversity.com',
  'uat': '.uoocuniversity.com',
  'test': '192.168.1.166'
};
export function getToken() {
  return Cookies.get(EnvTokenKey);
}

// 是为了深大优课后台和CRM其中之一登录都能跳转，所以不能取url地址
export function setToken(token, rememberMe) {
  // 兼容本地开发
  if (window.location.host.indexOf('localhost') === -1) {
    Cookies.set(EnvTokenKey, token, {
      expires: Config.tokenCookieExpires,
      domain: ENV_DOMAIN[ENV]
    });
  } else {
    Cookies.set(EnvTokenKey, token, {
      expires: Config.tokenCookieExpires,
      domain: ''
    });
  }
}
export function removeToken() {
  // 兼容本地开发
  if (window.location.host.indexOf('localhost') === -1) {
    Cookies.remove(EnvTokenKey, {
      domain: ENV_DOMAIN[ENV]
    });
  } else {
    Cookies.remove(EnvTokenKey);
  }
}
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      "append-to-body": "",
      "close-on-click-modal": false,
      "before-close": _vm.closeUserTag,
      visible: _vm.editData.visible,
      title: "用户标签",
      width: "500px",
      "custom-class": "user-tag-com"
    },
    on: {
      "update:visible": function updateVisible($event) {
        return _vm.$set(_vm.editData, "visible", $event);
      }
    }
  }, [_c("el-form", {
    attrs: {
      "label-position": "top"
    }
  }, _vm._l(_vm.userTagOpt, function (item) {
    return _c("el-form-item", {
      key: item.id,
      attrs: {
        label: item.label
      }
    }, [_c("el-checkbox-group", {
      attrs: {
        max: item.setting ? 99 : 1,
        border: ""
      },
      model: {
        value: item.selectedTag,
        callback: function callback($$v) {
          _vm.$set(item, "selectedTag", $$v);
        },
        expression: "item.selectedTag"
      }
    }, _vm._l(item.children, function (tagItem) {
      return _c("el-checkbox", {
        key: tagItem.id,
        attrs: {
          label: tagItem.id
        }
      }, [_vm._v(" " + _vm._s(tagItem.name) + " ")]);
    }), 1), _c("el-divider")], 1);
  }), 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.closeUserTag
    }
  }, [_vm._v("取消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.handleSubmit
    }
  }, [_vm._v("提交")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };
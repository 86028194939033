import Vue from 'vue';
import WelcomeBack from './index.vue';
var WelcomeBackCom = Vue.extend(WelcomeBack);
WelcomeBack.install = function (data) {
  var instance = new WelcomeBackCom({
    data: data
  }).$mount();
  document.body.appendChild(instance.$el);

  // Vue.nextTick(() => {
  //   instance.open();
  // });
};
export default WelcomeBack;
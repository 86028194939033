import "core-js/modules/es.array.concat.js";
import request from '@/utils/request';
var BASE_URL = 'api/customerLabel';
import qs from 'qs';

/**
 * @description 查询标签列表
 */
export function getCustomerLabel(params) {
  return request({
    url: "".concat(BASE_URL, "?").concat(qs.stringify(params, {
      indices: false
    })),
    method: 'GET'
  });
}

/**
 * @description 查询标签分类
 */
export function getCategory(params) {
  return request({
    url: "".concat(BASE_URL, "/category"),
    method: 'GET'
  });
}

/**
 * @description 新增标签分类
 * @param {object} data
 * @param {string} name 标签分类名称
 * @param {number} setting 标签设置，0-单选，1-多选
 * @param {number} sort 标签优先级
 */
function add(data) {
  return request({
    url: "".concat(BASE_URL, "/category"),
    method: 'POST',
    data: data
  });
}

/**
 * @description 修改标签分类
 * @param {object} data
 * @param {number} id
 * @param {string} name 标签分类名称
 * @param {number} setting 标签设置，0-单选，1-多选
 * @param {number} sort 标签优先级
 */
function edit(data) {
  return request({
    url: "".concat(BASE_URL, "/category"),
    method: 'PUT',
    data: data
  });
}

/**
 * @description 启用/停用标签
 * @param  {object} params
 * @param {number} id
 * @param {boolean} enable
 */
export function enabledLabel(params) {
  return request({
    url: "".concat(BASE_URL, "/enable/").concat(params.id, "/").concat(params.enable),
    method: 'PUT'
  });
}

/**
 * @param 新增标签
 * @param  {object} data
 * @param {number} categoryId
 * @param {string} name
 */
export function addLabel(data) {
  return request({
    url: "".concat(BASE_URL),
    method: 'POST',
    data: data
  });
}

/**
 * @param 修改标签
 * @param  {object} data
 * @param {number} id
 * @param {string} name
 */
export function editLabel(data) {
  return request({
    url: "".concat(BASE_URL),
    method: 'PUT',
    data: data
  });
}
export default {
  getCustomerLabel: getCustomerLabel,
  add: add,
  edit: edit
};
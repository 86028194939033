import "core-js/modules/es.array.concat.js";
import request from '@/utils/request';
var BASE_URL = 'api/onlineStatus';

/**
 * @description 修改在线状态
 * @param  {object} data
 * @param {number} id 在线记录编号
 * @param {number} status 更改后状态
 */
function edit(data) {
  return request({
    url: "".concat(BASE_URL, "/change"),
    method: 'POST',
    data: data
  });
}

/**
 * @description 获取在线状态更改记录
 * @param  {number} id 在线记录编号
 */
export function getRecordChangeLog(id) {
  return request({
    url: "".concat(BASE_URL, "/").concat(id, "/change"),
    method: 'GET'
  });
}

/**
 * @description 更新在线状态v6.8
 * @param  {object} data
 * @param  {number} userId
 * @param  {0 | 1} status 状态：0.在线；1.离开
 */
export function updateUserStatus(data) {
  return request({
    url: 'api/userOnlineStatus/updateUserStatus',
    method: 'POST',
    data: data
  });
}

/**
 * @description 登录后，重置在线状态v6.8
 */
export function resetUserStatus() {
  return request({
    url: 'api/userOnlineStatus/resetUserStatus',
    method: 'GET'
  });
}
export default {
  edit: edit
};
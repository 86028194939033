import _typeof from "/var/jenkins_home/workspace/crm-admin-uat/node_modules/@babel/runtime/helpers/esm/typeof.js";
import handleClipboard from '@/utils/clipboard';
import { validateNum } from './index';
export default {
  name: 'CustomerInput',
  props: {
    inputData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    var _this = this;
    return {
      // eslint-disable-next-line vue/no-reserved-keys
      _this: _this,
      disabled: _this.inputData.disabled,
      // 首次添加的时候，可被编辑
      value: _this.inputData.value
    };
  },
  computed: {
    canEdit: function canEdit() {
      return this.checkPer(['admin', 'customer:contactWay:update']);
    },
    canDelete: function canDelete() {
      var inputData = this.inputData,
        checkPer = this.checkPer;
      // 是最后一个的话，不给删除
      return checkPer(['admin', 'customer:contactWay:delete']) && !inputData.isOnlyOne;
    }
  },
  methods: {
    handleCopy: function handleCopy(e) {
      handleClipboard(this.value, e, '复制成功');
    },
    handleEdit: function handleEdit() {
      var canEdit = this.canEdit,
        disabled = this.disabled;
      if (canEdit) {
        this.disabled = !disabled;
      }
    },
    handleDelete: function handleDelete() {
      var canDelete = this.canDelete,
        inputData = this.inputData;
      if (canDelete) {
        this.$emit('delete', inputData.index);
      }
    },
    handleChange: function handleChange(val) {
      var _this$inputData = this.inputData,
        type = _this$inputData.type,
        index = _this$inputData.index;
      var tip = validateNum(type, val);
      if (_typeof(tip) === 'object') {
        this.$emit('error', {
          index: index,
          tip: tip,
          type: type
        });
      } else {
        this.$emit('true', {
          index: index,
          type: type,
          val: val
        });
      }
    }
  }
};